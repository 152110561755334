export namespace CodeEnvModel {
    /**
     * Generated from com.dataiku.dip.code.CodeEnvModel$StandardPythonInterpreter
     */
    export enum StandardPythonInterpreter {
        PYTHON27 = '2.7F',
        PYTHON34 = '3.4F',
        PYTHON35 = '3.5F',
        PYTHON36 = '3.6F',
        PYTHON37 = '3.7F',
        PYTHON38 = '3.8F',
        PYTHON39 = '3.9F',
        PYTHON310 = '3.1F',
        CUSTOM = '3.7F'
    }

    /**
     * Generated from com.dataiku.dip.code.CodeEnvModel$UsedCodeEnvRef
     */
    export interface UsedCodeEnvRef {
        envLang: CodeEnvModel.EnvLang;
        envName: string;
    }

    /**
     * Generated from com.dataiku.dip.code.CodeEnvModel$EnvLang
     */
    export enum EnvLang {
        PYTHON = 'python',
        R = 'R',
        JULIA = 'julia'
    }

    /**
     * Generated from com.dataiku.dip.code.CodeEnvModel$EnvImportSpecificationMode
     */
    export enum EnvImportSpecificationMode {
        SPECIFIED = 'SPECIFIED',
        ACTUAL = 'ACTUAL'
    }
}