<div class="line feature-store__feature-group-actions" *ngIf="!selectedFeature">
    <h4 [attr.data-qa-smid]="'source-project-label'">Source project</h4>
    <p><a [href]="linkToProject(details.datasetFullInfo.dataset.projectKey)" class="highlight" [innerHtml]="formatProjectName() | escapeHighlightedHtml"></a></p>
    <a (click)="!this.disableNavigator() && showNavigator(details.datasetFullInfo.dataset.projectKey, details.datasetFullInfo.dataset.name)" class="btn btn--secondary btn--icon" 
        [matTooltip]="navigateTitle" [class.disabled]="disableNavigator()">
        <i class="icon-compass" [attr.data-qa-smid]="'navigate-around'"></i>
    </a>
    <a class="btn btn--secondary btn--icon" (click)="viewInProject()"
    [matTooltip]="viewInProjectTitle" [class.disabled]="disableViewInProject()">
        <i class="icon-dku-nav_flow" [attr.data-qa-smid]="'flow-button'"></i>
    </a>
</div>

<div class="line">
    <h4>Data connection type</h4>
    <p>{{featureGroupType()}}</p>
</div>
<div class="block" *ngIf="details.datasetFullInfo.dataset.shortDesc">
    <h4>About</h4>
    <markdown class="markdown-description"
        [class.highlight-markdown]="hasShortDescHighlighted()"
        [from]=formatShortDesc()></markdown>
</div>
<div class="block" *ngIf="details.datasetFullInfo.dataset.description">
    <h4>Description</h4>
    <markdown class="markdown-description"
        [class.highlight-markdown]="hasDescriptionHighlighted()"
        [from]=formatDescription()></markdown>
</div>
<div class="line" *ngIf="selectedFeature && details.datasetFullInfo.dataset.tags.length > 0">
    <h4>Tags</h4>
    <div class="feature-store__feature-group-details-tags">
        <ul class="tags feature-store__tags-listing inline">
            <li *ngFor="let tag of details.datasetFullInfo.dataset.tags">
                <span class="tag feature-store__tag" [ngStyle]="{'background-color': tagBackgroundColor(details.datasetFullInfo.dataset.projectKey, tag)}">
                    <ui-global-tag [tag]="tag" objectType="DATASET"></ui-global-tag>
                </span>
            </li>
        </ul>
    </div>
</div>
<hr/>

<div class="line users">
    <h4>Users</h4>
    <div>
        <a *ngFor="let user of details.datasetFullInfo.timeline.allContributors" href="/profile/{{user.login}}/"
        [matTooltip]="user.displayName || user.login" class="avatar"
        toggle="tooltip" animation="false" container=".catalog-tooltips">
            <div>
                <user-picture [user]="user.login" [size]="24"></user-picture>
            </div>
        </a>
    </div>
</div>
<div class="line" *ngIf="details.datasetFullInfo.dataset.creationTag 
                        && details.datasetFullInfo.dataset.creationTag.lastModifiedOn 
                        && details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login">
    <h4>Created</h4>
    <p class="feature-store__created-modified-info">
        <span
            [matTooltip]="details.datasetFullInfo.dataset.creationTag.lastModifiedOn | friendlyDateTime"
            toggle="tooltip" animation="false" container=".catalog-tooltips">
                {{details.datasetFullInfo.dataset.creationTag.lastModifiedOn | friendlyTimeDeltaShort}} by
        </span>
        <span>
            <a href="/profile/{{details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login}}/"
                class="avatar"
                [matTooltip]="users[details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login] || details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login"
                toggle="tooltip" animation="false" container=".catalog-tooltips">
                    <user-picture [user]="details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login" [size]="24"></user-picture>
            </a>
        </span>
    </p>
</div>
<div class="line" *ngIf="details.datasetFullInfo.dataset.versionTag 
                        && details.datasetFullInfo.dataset.versionTag.lastModifiedOn 
                        && details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login">
    <h4>Last modified</h4>
    <p class="feature-store__created-modified-info">
        <span
            [matTooltip]="details.datasetFullInfo.dataset.versionTag.lastModifiedOn | friendlyDateTime"
            toggle="tooltip" animation="false" container=".catalog-tooltips">
                {{details.datasetFullInfo.dataset.versionTag.lastModifiedOn | friendlyTimeDeltaShort}} by
        </span>
        <span>
            <a href="/profile/{{details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login}}/"
                class="avatar"
                [matTooltip]="users[details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login || ''] || details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login"
                toggle="tooltip" animation="false" container=".catalog-tooltips">
                    <user-picture [user]="details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login" [size]="24"></user-picture>
            </a>
        </span>
    </p>
</div>
<div class="line">
    <h4>Last build</h4>
    <p [innerHtml]="lastBuildInfo()"></p>
</div>
<div class="line" *ngIf="details.datasetFullInfo.dataset.params.connection">
    <h4>Connection</h4>
    <p>{{details.datasetFullInfo.dataset.params.connection}}</p>
</div>

<dss-right-panel-schema
    wordingOption="feature"
    [columns]="datasetFeatures"
    [columnHighlight]="featuresHighlighting"
    [selected]="selectedFeature?._source?.name"
></dss-right-panel-schema>

<div class="accordion" *ngIf="numberOfUsages > 0">
    <h4 class="accordion-title" (click)="showUsage=!showUsage">
        <i [ngClass]="{'icon-chevron-up':showUsage, 'icon-chevron-down':!showUsage}"></i>
        Usage
        <span>
            (<span>{{numberOfUsages}}</span>)
        </span>
    </h4>
    <div [hidden]="!showUsage" class="feature-store__accordion-body">
        <div class="feature-store__feature-group-project-usage horizontal-flex" *ngFor="let project of details.usages.projectsWithAccess; let last=last;" [class.dku-border-bottom]="details.usages.usagesInNonAccessibleProjects > 0 || !last">
            <p><a [href]="linkToProject(project.projectKey)">{{project.projectName}} ({{project.projectKey}})</a></p>
            <a (click)="showNavigator(project.projectKey, details.datasetFullInfo.dataset.projectKey + '.' + details.datasetFullInfo.dataset.name)" class="btn btn--secondary btn--icon" alt="Navigate around" matTooltip="Navigate around">
                <i class="icon-compass"></i>
            </a>
            <a class="btn btn--secondary btn--icon" [href]="flowLink(project.projectKey, details.datasetFullInfo.dataset.projectKey + '.' + details.datasetFullInfo.dataset.name)" alt="See in flow" matTooltip="See in flow">
                <i class="icon-dku-nav_flow"></i>
            </a>
        </div>
        <div *ngIf="details.usages.usagesInNonAccessibleProjects > 0" class="feature-store__feature-group-no-access-to-project">
            <span>+ {{details.usages.usagesInNonAccessibleProjects}} non-accessible project{{details.usages.usagesInNonAccessibleProjects < 2 ? "":"s"}}</span>
        </div>
    </div>
</div>

<dataset-status objectName="" [checklists]="details.datasetFullInfo.dataset.checklists.checklists" [data]="details.datasetFullInfo" context="feature-store" [readOnly]="true"></dataset-status>

<related-by-type [elementsByType]="details.splitInputsByType" baseString="Related initial" [baseItemProjectKey]="details.datasetFullInfo.dataset.projectKey"></related-by-type>
<related-by-type [elementsByType]="details.splitOutputsByType" baseString="Related final" [baseItemProjectKey]="details.datasetFullInfo.dataset.projectKey"></related-by-type>
