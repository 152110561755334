<ng-template [ngIf]="noResults()" [ngIfElse]="searchResults">
    <div class="h100">
        <div *ngIf="noFiltersInSearch()" class="vertical-flex feature-store__empty-store">
            <div class="feature-store__empty-store-icon">
                <i class="icon-dku-feature-store"></i>
            </div>
            <div class="empty-list-cta">
                <h1>No feature visible</h1>
                <p>
                    Promote a dataset as a Feature Group to make it visible on this list. To enrich your data, search this list for features and add them to your projects. Feature groups from all projects you have access to will appear in here.
                </p>
                <p class="small">
                    <doclink-wrapper page="mlops/feature-store/index">Read the documentation</doclink-wrapper> to learn more.
                </p>
            </div>
        </div>
        <div *ngIf="!noFiltersInSearch()" class="alert alert-info">
            <h4>No match</h4>
            <p *ngIf="hasFacets()">
                Remove your filters to see more
                <a class="btn btn--secondary" (click)="resetSearch()">Remove all filters</a>
            </p>
        </div>
    </div>
</ng-template>
<ng-template #searchResults>
    <div class="vertical-flex h100">
        <div style="height: 50px; padding: 0 10px; " class="noflex dku-border-bottom">
            <div style=" align-items: center; display: flex; height: 100%;">
                <div style="width: 100%;">
                    <div class="pull-right">
                        <div class="counters" data-block="counter">
                            <div class="dib" style="font-size: 18px; margin-right: 1em; vertical-align: middle" [innerHtml]=featureCount()></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ul class="list-items noflex"
            *ngFor="let feature of items"
            >
            <div style="height: 70px;">
                <li (click)="selectFeature(feature)"
                    class="list-item"
                    [ngClass]="{
                        'selected': feature._id === selectedItem?._id,
                        'feature-store__item-already-in-project': featureIsAlreadyInCurrentProject(feature)
                    }"
                    line-height="70"
                    matTooltip="{{featureIsAlreadyInCurrentProject(feature)?'This Feature belongs to a Feature Group that is already used in current project.':''}}"
                    style="border-bottom: 1px #ccc solid; list-style: none; position: relative; height: 100%; align-items: center; display: flex;">
                    <div class="hit feature-group" dataset="item" style="display: flex; padding:0 0 0 0px; width: 100%; align-items: center;"
                    [attr.data-qa-smid]="'feature-' + (featureIsAlreadyInCurrentProject(feature)?'already-in-project-':'') + feature._source.projectKey + '-' + feature._source.dataset + '-' + feature._source.name">
                        <div class="hitContent feature-store__item">
                            <div class="hit-content-main vertical-flex feature-store__item-preview">
                                <div class="horizontal-flex">
                                    <h4 class="flex hit-content-main__title" [innerHtml]="formatItemName(feature) | escapeHighlightedHtml"></h4>
                                    <p class="feature-store__item-membership">
                                        <span [attr.data-qa-smid]="'from-feature-group'">
                                            <span class="feature-store__source-feature-group">From Feature Group: </span>
                                            <span [innerHTML]="formatSourceFeatureGroupName(feature) | escapeHighlightedHtml"></span>
                                        </span>
                                    </p>
                                </div>
                                
                                <div class="horizontal-flex feature-store__feature-attributes">
                                    <div><span>Type: {{feature._source.type | lowercase | capitalize}}</span></div>
                                    <div><span>Meaning: {{feature._source.meaning | meaningLabel}}</span></div>
                                    <div class="ellipsed" *ngIf="featureHasDescription(feature)">
                                        <span innerHTML="Description: {{formatFeatureDescription(feature) | escapeHighlightedHtml}}"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </div>
        </ul>
    </div>
</ng-template>