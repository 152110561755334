import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APIError, catchAPIError, ErrorContext } from '@core/dataiku-api/api-error';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { WaitingService } from '@core/overlays/waiting.service';
import { RunViewModel } from "@features/experiment-tracking/experiment-tracking.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ITaggingService } from 'src/generated-sources';
import { assertNever } from 'dku-frontend-core';
import { combineLatest, forkJoin, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { AccessibleObjectsService, MLflowExperimentRunOrigin, MLTask, PredictionMLTask, PythonCodeEnvPackagesUtils, SavedModel, SMStatus, SamplingParam } from 'src/generated-sources';
import { fairAny } from 'dku-frontend-core';

const CompatibilityStatus = PythonCodeEnvPackagesUtils.ExperimentTrackingCompatibilityInfo.CompatibilityStatus;

export interface ExperimentTrackingRunModelDeployModalComponentInput {
    projectKey: string;
    run: RunViewModel;
}

class PredictionTypeConfig {
    key: PredictionMLTask.PredictionType | "OTHER";
    label: string;
}

export const PREDICTION_TYPE: PredictionTypeConfig[] = [{
    key: PredictionMLTask.PredictionType.REGRESSION,
    label: 'Regression'
}, {
    key: PredictionMLTask.PredictionType.BINARY_CLASSIFICATION,
    label: 'Binary Classification'
}, {
    key: PredictionMLTask.PredictionType.MULTICLASS,
    label: 'Multiclass Classification'
}, {
    key: "OTHER",
    label: 'Other - not classification nor regression'
}
];

export const CLASSIFICATION: PredictionMLTask.PredictionType[] = [
    PredictionMLTask.PredictionType.BINARY_CLASSIFICATION,
    PredictionMLTask.PredictionType.MULTICLASS
];

enum ModalPanels {
    CREATE_SMV,
    CREATE_SM
}

@UntilDestroy()
@Component({
    selector: 'dss-experiment-tracking-run-model-deploy-modal',
    templateUrl: './experiment-tracking-run-model-deploy-modal.component.html',
    styleUrls: ['./experiment-tracking-run-model-deploy-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTrackingRunModelDeployModalComponent implements OnInit, ErrorContext {
    COMPATIBILITY_STATUS_INCOMPATIBLE = CompatibilityStatus.INCOMPATIBLE;
    COMPATIBILITY_STATUS_MAYBE_COMPATIBLE = CompatibilityStatus.MAYBE_COMPATIBLE;
    COMPATIBILITY_STATUS_TESTED_COMPATIBLE = CompatibilityStatus.TESTED_COMPATIBLE;

    run: RunViewModel;
    projectKey: string;

    modalTitle: string;
    defaultModel: string | null;

    codeEnvs: PythonCodeEnvPackagesUtils.CodeEnvExperimentTrackingCompat[];
    codeEnvsDescriptions: string[] = [];
    codeEnvMessage: string;
    codeEnvCompatibilityStatus: PythonCodeEnvPackagesUtils.ExperimentTrackingCompatibilityInfo.CompatibilityStatus;
    defaultCodeEnv: string | undefined;
    INDEX_OF_INHERIT = 0;
    INDEX_OF_EXPLICIT = 1;
    datasets: AccessibleObjectsService.AccessibleObject[];
    samplingMethods: string[];
    samplingMethodDescriptions: string [];
    datasetColumns: string[];
    classification = false;
    binaryClassification = false;
    useOptimalThreshold = true;
    thresholdOptimizationMetric: string = '';
    folderRef: string;
    path: string[];
    readonly supportedPredictionTypes: PredictionTypeConfig[] = PREDICTION_TYPE;
    currentPanel = ModalPanels.CREATE_SMV;
    readonly ModalPanels = ModalPanels;

    error?: APIError | null;
    form: FormGroup;
    formSM: FormGroup;

    refresh$: Subject<void> = new ReplaySubject(1);

    predictionType: PredictionTypeConfig | undefined;
    runPredictionTypeName: string | undefined;

    savedModels$: Observable<AccessibleObjectsService.AccessibleObject[]>;
    savedModelVersions: string[] = [];
    deployingModel: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: ExperimentTrackingRunModelDeployModalComponentInput,
        private dialogRef: MatDialogRef<ExperimentTrackingRunModelDeployModalComponentInput>,
        private fb: FormBuilder,
        private DataikuAPI: DataikuAPIService,
        private changeDetectionRef: ChangeDetectorRef,
        private waitingService: WaitingService,
        @Inject('$rootScope') private $rootScope: fairAny,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject('SamplingData') public SamplingData: fairAny,
        @Inject('PMLSettings') private PMLSettings: fairAny
    ) {
        this.run = data.run;
        this.projectKey = data.projectKey;

        this.modalTitle = `Deploying a model`;
        this.defaultModel = data.run.data.models.length == 1 ? data.run.data.models[0].artifactPath : null;

        this.path = this.run.info.artifactUri.split("//")[1].split("/");
        this.folderRef = this.path.shift() as string;

        this.form = this.fb.group({
            modelName: this.fb.control(this.defaultModel, [Validators.required]),
            savedModel: this.fb.control(null, [Validators.required]),
            versionId: this.fb.control("v01", [Validators.required, Validators.pattern(/^\w+$/)]),
            codeEnvName: this.fb.control(null, [Validators.required]),
            dataset: this.fb.control(null, [this.validateRequiredForNonOther.bind(this)]),
            samplingMethod: this.fb.control(SamplingParam.SamplingMethod.HEAD_SEQUENTIAL, []),
            maxRecords: this.fb.control(10000, [this.validateSamplingMaxRecords.bind(this), Validators.min(1)]),
            targetRatio: this.fb.control(1, [this.validateSamplingRatio.bind(this), Validators.min(0), Validators.max(1)]),
            samplingColumn: this.fb.control(1, [this.validateColumn.bind(this)]),
            targetColumn: this.fb.control(null, [this.validateRequiredForNonOther.bind(this)]),
            classes: this.fb.control(this.run.classes, [this.validateClasses.bind(this)]),
            activate: this.fb.control(true, []),
            binaryClassificationThreshold: this.fb.control(0.5, this.validateRequiredForBinaryClassifCustromThreshold.bind(this)),
            useOptimalThreshold: this.fb.control(this.useOptimalThreshold, this.validateRequiredForBinaryClassif.bind(this))
        });

        this.formSM = this.fb.group({
            smName: this.fb.control("model", [Validators.required]),
            predictionType: this.fb.control(null, [Validators.required])
        });

        this.samplingMethods = this.SamplingData.streamSamplingMethods
        this.samplingMethodDescriptions = this.SamplingData.streamSamplingMethodsDesc

    }

    validateRequiredForNonOther(control: AbstractControl): ValidationErrors | null {
        if (this.predictionType?.key === "OTHER") {
            return null;
        }
        return Validators.required(control);
    }

    validateSamplingMaxRecords(control: AbstractControl): ValidationErrors | null {
        if(!control.parent || control.parent.get('samplingMethod') == null){
            return null;
        }
        if(this.samplingMethodWithMaxRecords(control.parent.get('samplingMethod')!!.value)) {
            return Validators.required(control);
        }
        return null;
    }

    validateRequiredForBinaryClassifCustromThreshold(control: AbstractControl): ValidationErrors | null {
        if (this.predictionType?.key === PredictionMLTask.PredictionType.BINARY_CLASSIFICATION && !this.useOptimalThreshold) {
            return Validators.required(control);
        }
        return null;
    }

    validateRequiredForBinaryClassif(control: AbstractControl): ValidationErrors | null {
        if (this.predictionType?.key === PredictionMLTask.PredictionType.BINARY_CLASSIFICATION) {
            return Validators.required(control);
        }
        return null;
    }

    samplingMethodWithRatio(samplingMethod: SamplingParam.SamplingMethod) {
        return samplingMethod == SamplingParam.SamplingMethod.RANDOM_FIXED_RATIO ||
        samplingMethod == SamplingParam.SamplingMethod.CLASS_REBALANCE_TARGET_RATIO_APPROX;
    }

    samplingMethodWithMaxRecords(samplingMethod: SamplingParam.SamplingMethod) {
        return samplingMethod == SamplingParam.SamplingMethod.HEAD_SEQUENTIAL ||
        samplingMethod == SamplingParam.SamplingMethod.RANDOM_FIXED_NB ||
        samplingMethod == SamplingParam.SamplingMethod.COLUMN_BASED ||
        samplingMethod == SamplingParam.SamplingMethod.CLASS_REBALANCE_TARGET_NB_APPROX;
    }


    samplingMethodWithColumn(samplingMethod: SamplingParam.SamplingMethod) {
        return samplingMethod == SamplingParam.SamplingMethod.COLUMN_BASED;
    }

    samplingMethodWithTarget(samplingMethod: SamplingParam.SamplingMethod) {
        return samplingMethod == SamplingParam.SamplingMethod.CLASS_REBALANCE_TARGET_NB_APPROX;
    }


    validateSamplingRatio(control: AbstractControl): ValidationErrors | null {
        if(!control.parent || control.parent.get('samplingMethod') == null){
            return null;
        }
        if(this.samplingMethodWithRatio(control.parent.get('samplingMethod')!!.value)) {
            return Validators.required(control);
        }
        return null;
    }

    validateColumn(control: AbstractControl): ValidationErrors | null {
        if(!control.parent || control.parent.get('samplingMethod') == null){
            return null;
        }
        if(this.samplingMethodWithColumn(control.parent.get('samplingMethod')!!.value)) {
            return Validators.required(control);
        }
        return null;
    }

    validateClasses(control: AbstractControl): ValidationErrors | null {
        let errors: (ValidationErrors | null) = control.errors || {};
        const classes = control.value;
        const predictionTypeKey = this.predictionType?.key;

        if (predictionTypeKey === PredictionMLTask.PredictionType.BINARY_CLASSIFICATION
            && (classes && classes.length !== 2)) {
            errors['binaryIncorrectNumClasses'] = { value: classes };
        } else {
            delete errors['binaryIncorrectNumClasses'];
        }

        if (predictionTypeKey === PredictionMLTask.PredictionType.MULTICLASS
            && (classes && classes.length < 2)) {
            errors['multiclassIncorrectNumClasses'] = { value: classes };
        } else {
            delete errors['multiclassIncorrectNumClasses'];
        }

        if (Object.keys(errors).length == 0) {
            errors = null;
        }

        return errors;
    }

    onClassValuesListValidityChanged(valid: boolean) {
        let errors: (ValidationErrors | null) = this.form.controls.classes.errors || {};

        if (!valid) {
            errors['required'] = true;
        } else {
            delete errors['required'];
        }

        if (Object.keys(errors).length == 0) {
            errors = null;
        }

        this.form.controls.classes.setErrors(errors);
    }

    ngOnInit(): void {

        if (this.run.predictionType) {
            const ptDesc = PREDICTION_TYPE.find(pt => pt.key.toString() === this.run.predictionType);
            this.runPredictionTypeName = ptDesc?.label;
            this.formSM.patchValue({
                predictionType: this.run.predictionType
            });
            this.setPredictionType(this.run.predictionType as PredictionMLTask.PredictionType);
        }
        // SM list may evolve if the user creates a new one.
        this.savedModels$ = this.refresh$.pipe(
            switchMap(() =>
                this.DataikuAPI.taggableObjects.listAccessibleObjects(this.projectKey, "SAVED_MODEL", 'READ')
                    .pipe(this.waitingService.bindSpinner())
            ),
            map(sms => {
                const mlflowModels = sms.filter(sm => sm.object.savedModelType === "MLFLOW_PYFUNC");
                if (!this.run.predictionType) {
                    return mlflowModels;
                }
                const searchedPredictionType = (this.run.predictionType === "OTHER") ? undefined : this.run.predictionType;
                return mlflowModels.filter(sm => sm.object.miniTask.predictionType === searchedPredictionType);
            })
        );

        // datasets and code envs lists are considered constants
        combineLatest([
            this.DataikuAPI.taggableObjects.listAccessibleObjects(this.projectKey, "DATASET", 'READ').pipe(this.waitingService.bindSpinner()),
            this.DataikuAPI.codeEnvs.listPythonWithExperimentTrackingPackages(this.projectKey).pipe(this.waitingService.bindSpinner()),
        ]).pipe(
            untilDestroyed(this)
        ).subscribe(([datasets, codeEnvs]) => {
            this.datasets = datasets.map(dataset => {
                // The label is originally the id. For shared datasets this can be confusing, so use the smartId instead.
                return { ...dataset, label: dataset.smartId }
            })
            this.codeEnvs = [...codeEnvs.envs].sort(
                (a, b) => {
                    if (a.compatibilityInfo.compatibilityStatus === b.compatibilityInfo.compatibilityStatus) {
                        return a.envName.localeCompare(b.envName);
                    }
                    if (a.compatibilityInfo.compatibilityStatus === CompatibilityStatus.TESTED_COMPATIBLE) {
                        return -1;
                    }
                    if (b.compatibilityInfo.compatibilityStatus === CompatibilityStatus.TESTED_COMPATIBLE) {
                        return 1;
                    }
                    if (a.compatibilityInfo.compatibilityStatus === CompatibilityStatus.MAYBE_COMPATIBLE) {
                        return -1;
                    }
                    if (b.compatibilityInfo.compatibilityStatus === CompatibilityStatus.MAYBE_COMPATIBLE) {
                        return 1;
                    }
                    return a.envName.localeCompare(b.envName);
                }
            );
            this.setCodeEnvsDescriptions(this.codeEnvs);
            const defaultCodeEnv = this.codeEnvs.find(env => env.envName === codeEnvs.resolvedInheritDefault);
            this.defaultCodeEnv = defaultCodeEnv?.envName;
            const codeEnvToSet = this.run.codeEnvName || defaultCodeEnv?.envName;
            if (codeEnvToSet) {
                // change detection must have kicked in before updating the form
                setTimeout(() => {
                    this.form.patchValue({
                        codeEnvName: codeEnvToSet
                    });
                });
                this.changeDetectionRef.detectChanges();
            }
        });

        this.form.controls.dataset.valueChanges.pipe(
            distinctUntilChanged(),
            filter(dataset => dataset), // if not empty
            switchMap(dataset => {
                const ds: AccessibleObjectsService.AccessibleObject = dataset;
                return this.DataikuAPI.datasets.get(ds.projectKey, ds.id, this.projectKey).pipe(
                    this.waitingService.bindSpinner()
                )
            }),
            untilDestroyed(this)
        ).subscribe(x => {
            this.datasetColumns = x.schema.columns.map((x: any) => x.name);
            if (this.run.target && this.datasetColumns.includes(this.run.target)) {
                // change detection must have kicked in before updating the form
                setTimeout(() => {
                    this.form.patchValue({
                        targetColumn: this.run.target
                    });
                });
            }
            this.changeDetectionRef.detectChanges();
        });

        this.form.controls.codeEnvName.valueChanges.pipe(
            distinctUntilChanged(),
            untilDestroyed(this)
        ).subscribe(selectedCodeEnvName => {
            if (!selectedCodeEnvName || !this.codeEnvs) {
                this.codeEnvCompatibilityStatus = CompatibilityStatus.TESTED_COMPATIBLE;
                this.codeEnvMessage = '';
                return;
            }
            const codeEnv = this.codeEnvs.find(codeEnv => codeEnv.envName == selectedCodeEnvName);
            if (!codeEnv) {
                this.codeEnvCompatibilityStatus = CompatibilityStatus.TESTED_COMPATIBLE;
                this.codeEnvMessage = '';
                return;
            }
            this.codeEnvCompatibilityStatus = codeEnv.compatibilityInfo.compatibilityStatus;
            this.codeEnvMessage = this.getEnvDescription(codeEnv);
        });

        combineLatest([this.savedModels$, this.form.controls.savedModel.valueChanges.pipe(
            distinctUntilChanged(),
            filter(savedModel => savedModel), // if not empty
            untilDestroyed(this)
        )]).pipe(
            switchMap(([savedModelsList, smId]) => {
                if (smId) {
                    const curSm = savedModelsList.find(sm => sm.id === smId);
                    if (curSm) {
                        return forkJoin([
                            of(savedModelsList),
                            of(smId),
                            this.listSavedModelVersions$(curSm.object)
                        ]);
                    }
                }
                return forkJoin([
                    of(savedModelsList),
                    of(smId),
                    of([])
                ]);
            })
        ).subscribe(([savedModelsList, smId, savedModelVersions]) => {
            this.savedModelVersions = savedModelVersions;
            const curSm = savedModelsList.find(sm => sm.id === smId);
            if (curSm) {
                this.setPredictionType(curSm.object.miniTask.predictionType);
                if (curSm.object.miniTask.predictionType === 'BINARY_CLASSIFICATION') {
                    this.thresholdOptimizationMetric = this.PMLSettings.task.thresholdOptimizationMetrics
                    .find((elem: fairAny) => elem[0] === curSm.object.miniTask.modeling.metrics.thresholdOptimizationMetric)[1] || '';
                } else {
                    this.thresholdOptimizationMetric = '';
                }
            } else if (this.run?.predictionType) {
                this.setPredictionType(this.run?.predictionType as PredictionMLTask.PredictionType);
            }
            setTimeout(() => {
                // Another subtle change detection trick to fix the following scenario:
                // you have selected a binary classification model and filled everything except classes,
                // so the 'Deploy' button is disabled, if you then update to a regression model, the button should be
                // enabled, but this was not the case.
                this.checkForm();
                this.changeDetectionRef.detectChanges();
            });
        });

        this.refresh$.next();
    }

    checkForm(): void {
        Object.keys(this.form.controls).forEach(key => {
            this.form.controls[key].updateValueAndValidity();
        });
        this.form.updateValueAndValidity();
    }

    setCodeEnvsDescriptions(envs: PythonCodeEnvPackagesUtils.CodeEnvExperimentTrackingCompat[]) {
        const descriptions = envs.map(this.getHtmlEnvDescription.bind(this));

        // Not doing this.codeEnvsDescriptions = ... directly because
        // the description would not show up anymore. I expect it has to do with
        // the fact that the underlying component is in angularJS.
        // Calling detectChanges does not fix it.
        this.codeEnvsDescriptions.splice(0, this.codeEnvsDescriptions.length);
        this.codeEnvsDescriptions.push(...descriptions);
    }

    getHtmlEnvDescription(env: PythonCodeEnvPackagesUtils.CodeEnvExperimentTrackingCompat) {
        const description = this.getEnvDescription(env);
        if (env.compatibilityInfo.compatibilityStatus == CompatibilityStatus.TESTED_COMPATIBLE) {
            return description;
        }
        if (env.compatibilityInfo.compatibilityStatus == CompatibilityStatus.MAYBE_COMPATIBLE) {
            return `<span class='text-warning'>${description}</span>`;
        } else {
            return `<span class='text-error'>${description}</span>`;
        }
    }

    getEnvDescription(env: PythonCodeEnvPackagesUtils.CodeEnvExperimentTrackingCompat) {
        if (env.compatibilityInfo.compatibilityStatus == CompatibilityStatus.TESTED_COMPATIBLE) {
            return "No incompatibility detected in this code environment";
        }
        // unique + join with ' ; '
        let incompatibilityReasons =
            [...new Set(env.compatibilityInfo.reasons || [])]
                .reduce((acc, reason) => acc ? acc + ' ; ' + reason : reason, '')
        if (incompatibilityReasons.length < 0) {
            incompatibilityReasons = "No incompatibility detected in this code environment";
        }
        return incompatibilityReasons;
    }

    setPredictionType(predictionType: PredictionMLTask.PredictionType) {
        this.classification = CLASSIFICATION.includes(predictionType) ? true : false;
        this.binaryClassification = predictionType === PredictionMLTask.PredictionType.BINARY_CLASSIFICATION;
        const ptDesc = PREDICTION_TYPE.find(pt => pt.key == (predictionType?predictionType:"OTHER"));
        this.predictionType = ptDesc;
    }

    dismiss(): void {
        this.dialogRef.close('');
    }

    pushError(error: APIError | null): void {
        this.error = error;
        this.changeDetectorRef.markForCheck();
    }

    resetError() {
        this.pushError(null);
    }

    deploy(): void {
        this.path.push(this.form.value.modelName);
        const origin: MLflowExperimentRunOrigin = {
            experimentId: this.run.info.experimentId,
            runId: this.run.info.runId,
            artifactURI: this.run.info.artifactUri,
            modelSubfolder: this.form.value.modelName
        };
        this.deployingModel = true;
        this.form.markAsPristine();

        const selection = {} as SamplingParam;
        selection.samplingMethod = this.form.value.samplingMethod;
        if(this.samplingMethodWithRatio(selection.samplingMethod)) {
            selection.targetRatio = this.form.value.targetRatio;
        }
        if(this.samplingMethodWithMaxRecords(selection.samplingMethod)) {
            selection.maxRecords = this.form.value.maxRecords;
        }
        if(this.samplingMethodWithColumn(selection.samplingMethod)) {
            selection.column = this.form.value.samplingColumn;
        }
        else if(this.samplingMethodWithTarget(selection.samplingMethod)) {
            selection.column = this.form.value.targetColumn;
        }

        // Calling API
        this.DataikuAPI.savedModels.deployMLflowModel(
            this.projectKey,
            this.run.predictionType || this.form.value.predictionType,
            this.form.value.savedModel,
            this.form.value.codeEnvName,
            this.folderRef,
            this.path.join("/"),
            this.form.value.versionId,
            this.form.value.activate,
            this.form.value.dataset?.smartId,
            this.form.value.targetColumn,
            this.form.value.classes,
            origin,
            this.form.value.binaryClassificationThreshold,
            this.form.value.useOptimalThreshold,
            selection
        ).pipe(
            finalize(() => { this.deployingModel = false }),
            this.waitingService.bindOverlayAndWaitForResult(),
            catchAPIError(this, false),
            this.waitingService.bindStaticOverlay()
        ).subscribe(() => { this.dialogRef.close(true) });
    }

    createMLflowSavedModel(): void {
        // Calling API
        this.DataikuAPI.savedModels.createExternalSavedModel(
            this.projectKey,
            SavedModel.SavedModelType.MLFLOW_PYFUNC,
            this.formSM.value.predictionType == "OTHER"?undefined:this.formSM.value.predictionType,
            this.formSM.value.smName
        ).pipe(
            tap((sm: SavedModel) => {
                this.DataikuAPI.flow.zones.moveToItemZone(
                    this.projectKey,
                    { id: this.folderRef, projectKey: this.projectKey, type: ITaggingService.TaggableType.MANAGED_FOLDER, workspaceKey: '' },
                    [{ id: sm.id, projectKey: sm.projectKey, type: ITaggingService.TaggableType.SAVED_MODEL, workspaceKey: '' }])
                    .pipe(
                        catchAPIError(this, false)
                    ).subscribe();
            }),
            catchAPIError(this, false)
        ).subscribe((sm) => {
            this.currentPanel = ModalPanels.CREATE_SMV;

            // let's wait for the new list to be loaded before patching the value
            // or the selection may fail
            this.savedModels$.pipe(take(1)).subscribe(() => {
                this.form.patchValue({
                    savedModel: sm.id
                });
            });
            this.refresh$.next();
            this.changeDetectionRef.detectChanges();
        });
    }

    listSavedModelVersions$(savedModel: SavedModel): Observable<string[]> {
        const taskType = savedModel.miniTask.taskType;
        let observable: Observable<SMStatus>;
        switch (taskType) {
            case MLTask.MLTaskType.PREDICTION:
                observable = this.DataikuAPI.savedModels.prediction.getStatus(this.projectKey, savedModel.id);
                break;
            case MLTask.MLTaskType.CLUSTERING:
                observable = this.DataikuAPI.savedModels.clustering.getStatus(this.projectKey, savedModel.id);
                break;
            default:
                assertNever(taskType);
        }
        return observable.pipe(
            map(smStatus => smStatus?.versions?.map(version => version.versionId as string) || [])
        );
    }

    isAutomationNode(): boolean {
        return this.$rootScope.appConfig.isAutomation;
    }

    get createSMVModalTitle(): string {
        let ret = "Create new Saved Model";
        if (this.run.predictionType) {
            const ptDesc = PREDICTION_TYPE.find(pt => pt.key.toString() === this.run.predictionType);
            if (ptDesc) {
                ret += ` (type ${ptDesc.label})`;
            }
        }
        return ret;
    }

}
