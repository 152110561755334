import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { User } from '@shared/models';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceSecurityService } from '../../services';
import { ROLE_LIST, DEFAULT_PERMISSION } from '../../constants';
import { DKURootScope, Role } from '../../models';

interface EnrichedPermissionItem extends Workspace.PermissionItem {
    displayName: string;
    exists: boolean;
    role: Role;
}

@Component({
    selector: 'workspace-permissions-edition',
    templateUrl: './workspace-permissions-edition.component.html',
    styleUrls: ['./workspace-permissions-edition.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspacePermissionsEditionComponent implements OnChanges {
    @Input() workspace: Workspace;
    /** List of users than can be added in the workspace. Warning: here user's login is stored as 'login' */
    @Input() users?: Array<User> | null;
    /** List of groups that can be added in the workspace. */
    @Input() groups?: Array<string> | null;
    /** True if editing the workspace instead of creating it. */
    @Input() editionMode: boolean;

    @Output() permissionsChange = new EventEmitter<Workspace.PermissionItem[]>();

    roleList = ROLE_LIST;

    availableUsers?: Array<User> | null;
    permissions: Workspace.PermissionItem[];
    canEdit = false;
    currentUser: User;
    memberFilter = '';

    constructor(
        private workspaceSecurity: WorkspaceSecurityService,
        @Inject('$rootScope') private $rootScope: DKURootScope,
    ) {
        this.currentUser = this.$rootScope.appConfig.user;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.canEdit = this.editionMode ? this.workspace.currentUserPermissions.admin : this.workspaceSecurity.canCreateWorkspaces();
        this.setPermission([...this.workspace.permissions], false);
        if (changes.users) {
            this.availableUsers = this.editionMode ? this.users : this.users?.filter(user => user.login !== this.currentUser.login);
        }
    }

    setPermission(permissions: Workspace.PermissionItem[], emit = true): void {
        this.permissions = permissions;
        if (emit) {
            this.permissionsChange.emit(this.permissions);
        }
    }

    mapGroup(group: string): string {
        return group === '$$ALL_USERS$$' ? 'All users' : group;
    }

    mapPermission(permission: Workspace.PermissionItem): EnrichedPermissionItem | null {
        const role = this.workspaceSecurity.getRoleFromPermission(permission);
        if (permission.user) {
            const user = this.users?.find(user => user.login === permission.user);
            const displayName = user?.displayName || `${permission.user} (deleted)`;
            return { ...permission, displayName, exists: !!user, role };
        } else if (permission.group) {
            return { ...permission, displayName: this.mapGroup(permission.group), exists: true, role };
        }
        return null;
    }

    trackPermission(index: number, permission: Workspace.PermissionItem) {
        if (permission.user) {
            return 'u_' + permission.user;
        }
        if (permission.group) {
            return 'g_' + permission.group;
        }
        return permission;
    }

    onMemberSearchChange(memberFilter: string) {
        this.memberFilter = memberFilter;
    }

    onGroupPermissionsChange(change: MatSelectChange): void {
        const groups = change.value as string[];

        this.setPermission([
            ...groups.map(group =>
                this.permissions.find(permission => permission.group === group) || ({ ...DEFAULT_PERMISSION, group })
            ),
            ...this.permissions.filter(permission => !permission.group),
        ]);
    }

    onUserPermissionChange(change: MatSelectChange): void {
        const users = change.value as string[];

        this.setPermission([
            ...this.permissions.filter(permission => !permission.user),
            ...users.map(user =>
                this.permissions.find(permission => permission.user === user) || ({ ...DEFAULT_PERMISSION, user })
            ),
        ]);
    }

    deletePermission(permissionToDelete: EnrichedPermissionItem): void {
        const newPermissions = [
            ...this.permissions.filter(permission => permission.user !== permissionToDelete.user || permission.group !== permissionToDelete.group)
        ];
        this.setPermission(newPermissions);
    }

    updatePermissionRole(permissionToUpdate: EnrichedPermissionItem, newRole: string): void {
        const permission = this.permissions.find(permission => permission.user === permissionToUpdate.user && permission.group === permissionToUpdate.group);
        const newPermissions = this.workspaceSecurity.getPermissionFromRole(newRole);

        if (permission && newPermissions) {
            Object.assign(permission, newPermissions);
            this.setPermission([...this.permissions]);
        }
    }

}
