<ng-template [ngIf]="noResults()" [ngIfElse]="searchResults">
    <div class="h100">
        <div *ngIf="noFiltersInSearch()" class="vertical-flex feature-store__empty-store">
            <div class="feature-store__empty-store-icon">
                <i class="icon-dku-feature-store"></i>
            </div>
            <div class="empty-list-cta">
                <h1>No feature group visible</h1>
                <p>
                    Promote a dataset as a Feature Group to make it visible on this list. To enrich your data, search this list for features and add them to your projects. Feature groups from all projects you have access to will appear in here.
                </p>
                <p class="small">
                    <doclink-wrapper page="mlops/feature-store/index">Read the documentation</doclink-wrapper> to learn more.
                </p>
            </div>
        </div>
        <div *ngIf="!noFiltersInSearch()" class="alert alert-info">
            <h4>No match</h4>
            <p *ngIf="hasFacets()">
                Remove your filters to see more
                <a class="btn btn--secondary" (click)="resetSearch()">Remove all filters</a>
            </p>
        </div>
    </div>
</ng-template>

<ng-template #searchResults>
    <div class="vertical-flex h100">
        <div style="height: 50px; padding: 0 10px; " class="noflex dku-border-bottom">
            <div style=" align-items: center; display: flex; height: 100%;">
                <div style="width: 100%;">
                    <div class="pull-right">
                        <div class="counters" data-block="counter">
                            <div class="dib" style="font-size: 18px; margin-right: 1em; vertical-align: middle" [innerHtml]=featureGroupCount()></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ul class="list-items noflex"
            *ngFor="let featureGroup of items"
            >
            <div style="height: 70px;">
                <li (click)="selectFeatureGroup(featureGroup)"
                    class="list-item"
                    [ngClass]="{
                        'selected': featureGroup._id === selectedItem?._id,
                        'feature-store__item-already-in-project': featureGroupIsAlreadyInCurrentProject(featureGroup)
                    }"
                    line-height="70"
                    matTooltip="{{featureGroupIsAlreadyInCurrentProject(featureGroup)?'This Feature Group is already used in current project.':''}}"
                    style="border-bottom: 1px #ccc solid; list-style: none; position: relative; height: 100%; align-items: center; display: flex;">
                    <div class="hit feature-group" dataset="item" style="display: flex; padding:0 0 0 0px; width: 100%; align-items: center;"
                        [attr.data-qa-smid]="'feature-group-' + (featureGroupIsAlreadyInCurrentProject(featureGroup)?'already-in-project-':'') + featureGroup._source.projectKey + '-' + featureGroup._source.id">
                        <div class="hitContent horizontal-flex feature-store__item">
                            <div class="hit-content-main vertical-flex feature-store__item-preview">
                                <h4 class="flex hit-content-main__title" [innerHtml]="formatItemName(featureGroup) | escapeHighlightedHtml"></h4>
                                <div class="description-preview ellipsed" *ngIf="featureGroupHasDescription(featureGroup)">
                                    <span [innerHtml]="formatFeatureGroupDescription(featureGroup) | escapeHighlightedHtml"></span>
                                </div>
                                <div class="ellipsed" *ngIf="featureGroupHasHighlightedColumns(featureGroup)">
                                    <span [innerHtml]="featureGroupHighlightedColumns(featureGroup) | escapeHighlightedHtml"></span>
                                </div>
                                <div *ngIf="featureGroupHasTags(featureGroup)" class="matching_part feature-store__feature-group-attribute">
                                    <ul class="tags feature-store__tags-listing inline">
                                        <li *ngFor="let tag of featureGroupTags(featureGroup)">
                                            <span class="tag feature-store__tag" [ngStyle]="{'background-color': tagBackgroundColor(featureGroup._source.projectKey, tag)}" [class.feature-store__highlighted-tag]="tagIsHighlighted(featureGroup, tag)">
                                                <ui-global-tag [tag]="tag" [objectType]="featureGroup._type.toUpperCase()" [attr.data-qa-smid]="tagIsHighlighted(featureGroup, tag)?'highlighted':''"></ui-global-tag>
                                            </span>
                                        </li>
                                        <li *ngIf="numberOfTagsNotDisplayed(featureGroup) > 0">
                                            <span class="feature-store__more-tags">
                                                {{numberOfTagsNotDisplayed(featureGroup)}}&nbsp;more...
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div [attr.data-qa-smid]="'feature-group-project_name'">
                                <p class="feature-store__item-membership">
                                    <span>
                                        <span [innerHtml]="formatItemProjectName(featureGroup) | escapeHighlightedHtml"></span>
                                    </span>
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </li>
            </div>
        </ul>
    </div>    
</ng-template>