import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FacetMap, Feature } from "../feature-store-models";
import { formatDescription, formatSourceFeatureGroupName } from "../highlight-utils";
import { Hit } from "../query-result-models";
import { SearchResultsComponent } from "../search-results.directive";

@Component({
  selector: "feature-search-results",
  templateUrl: "./feature-search-results.component.html",
  styleUrls: ["./feature-search-results.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureSearchResultsComponent extends SearchResultsComponent {
  @Input() items?: Hit<Feature>[];
  @Input() selectedItem?: Hit<Feature>;
  @Input() searchQuery!: string;
  @Input() facetMap!: FacetMap;
  @Input() projectKey?: string;
  @Input() datasetsInCurrentProject!: string[];
  @Output() resetFiltersEvent: EventEmitter<void> = new EventEmitter();
  @Output() featureSelectedEvent: EventEmitter<Hit<Feature>> = new EventEmitter();

  constructor() {
    super();
  }

  featureCount() {
    const count = this.items ? this.items.length : 0;
    return `<strong>${count}</strong> Feature${count < 2 ? "" : "s"}`;
  }

  formatSourceFeatureGroupName(feature: Hit<Feature>) {
    return formatSourceFeatureGroupName(feature.highlight || {}, feature._source.dataset || "");
  }

  formatFeatureDescription(feature: Hit<Feature>) {
    return formatDescription(feature.highlight || {}, feature._source.description || "");
  }

  featureHasDescription(feature: Hit<Feature>) {
    return !!feature._source.description;
  }

  featureIsAlreadyInCurrentProject(feature: Hit<Feature>) {
    return this.datasetsInCurrentProject.includes(feature._source.projectKey + "." + feature._source.dataset);
  }

  selectFeature(feature: Hit<Feature>) {
    this.featureSelectedEvent.emit(feature);
  }
}
