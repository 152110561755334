<div class="dataset noflex object-icon universe-background">
    <div class="middle"><i class='icon' class='icon-dku-feature-group'></i></div>
</div>
<h2 class="flex ellipsed" matTooltip="{{details.datasetFullInfo.dataset.name}}">
    <span [innerHtml]="formatSourceFeatureGroupName() | escapeHighlightedHtml"></span>
</h2>
<div class="btn-items feature-store__feature-group-actions">
    <a (click)="useInProject()" class="btn btn--secondary btn--icon" alt="Use in project" [matTooltip]="useInProjectTitle"
        [class.disabled]="disableUseInProject()" wt1Click="feature-group-use" [attr.data-qa-smid]="'use-it'+(disableUseInProject()?'-disabled':'')">
        <i class="icon-dku-share"></i> Use
    </a>
    <a [href]="datasetLink()" class="btn btn--secondary btn--icon" alt="Explore" matTooltip="Explore">
        <i class="icon-dku-explore"></i> Explore
    </a>
    <a (click)="removeFromFeatureStore()" class="btn btn--secondary btn--icon"
        *ngIf="displayRemoveButton()"
        [matTooltip]="removeFromFeatureStoreLabel()" [class.disabled]="!mayManageFeatureStore()"
        wt1Click="feature-group-unset" [attr.data-qa-smid]="'remove-from-fs'+(!mayManageFeatureStore()?'-disabled':'')">
        <i class="icon-dku-unset-label-feature-store"></i> Remove
    </a>
</div>
