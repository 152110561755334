import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { DataikuWT1Module } from 'dku-frontend-core';
import { MigrationModule } from '@migration/migration.module';
import { UIRouterModule } from '@migration/modules';
import { ComponentsModule } from '@shared/components/components.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FacetListComponent } from './facet-list/facet-list.component';
import { FeatureSearchResultsComponent } from './feature-search-results/feature-search-results.component';
import { FeatureHeaderComponent } from './feature-header/feature-header.component';
import { FeatureDetailsComponent } from './feature-details/feature-details.component';
import { FeatureGroupSearchResultsComponent } from './feature-group-search-results/feature-group-search-results.component';
import { FeatureGroupHeaderComponent } from './feature-group-header/feature-group-header.component';
import { FeatureGroupDetailsComponent } from './feature-group-details/feature-group-details.component';
import { FeatureStoreComponent } from './feature-store.component';
import { FeatureStoreService } from './feature-store.service';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    FeatureStoreComponent,
    SearchBarComponent,
    FeatureSearchResultsComponent,
    FeatureGroupSearchResultsComponent,
    FeatureHeaderComponent,
    FeatureGroupHeaderComponent,
    FeatureDetailsComponent,
    FeatureGroupDetailsComponent,
    FacetListComponent,
  ],
  exports: [
    FeatureStoreComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule,
    PipesModule,
    ReactiveFormsModule,
    WidgetsModule,
    DataikuAPIModule,
    ComponentsModule,
    MigrationModule,
    DataikuWT1Module,
    MatTooltipModule
  ],
  providers: [
    FeatureStoreService,
  ]
})
export class FeatureStoreModule { }
