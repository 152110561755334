import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges } from "@angular/core";
import { Column } from "@shared/components/right-panel-summary/right-panel-schema/right-panel-schema.component";
import { fairAny } from "dku-frontend-core";
import { FeatureGroupDetails } from "src/generated-sources";
import { Feature } from "../feature-store-models";
import { FeatureStoreService } from "../feature-store.service";
import {
  formatDescription,
  formatProjectName,
  formatShortDesc,
  hasDescriptionHighlighted,
  hasShortDescHighlighted
} from "../highlight-utils";
import { Highlight, Hit } from "../query-result-models";

@Component({
  selector: "feature-group-details",
  templateUrl: "./feature-group-details.component.html",
  styleUrls: ["./feature-group-details.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureGroupDetailsComponent implements OnChanges {
  @Input() projectKey?: string;
  @Input() details!: FeatureGroupDetails;
  @Input() highlight!: Highlight;
  @Input() users!: Record<string, string>;
  @Input() datasetsInCurrentProject!: string[];
  @Input() selectedFeature?: Hit<Feature>;
  @Input() featureHighlight?: Highlight;

  numberOfUsages: number = 0;
  showUsage: boolean = true;
  navigateTitle: string = "Navigate around";
  viewInProjectTitle: string = "See in flow";

  datasetFeatures: Column[] = [];
  featuresHighlighting: string[] = []; // merges the column and column.plaintext highlighting from the highlight field of ES

  constructor(
    @Inject("TypeMappingService") private typeMappingService: fairAny,
    private featureStoreService: FeatureStoreService
  ) {}

  ngOnChanges() {
    this.featuresHighlighting = [
      ...(this.highlight.column || []),
      ...(this.highlight["column.plaintext"] || []),
      ...(this.featureHighlight?.name || []),
      ...(this.featureHighlight?.["name.plaintext"] || []),
    ];
    this.numberOfUsages =
      this.details.usages.projectsWithAccess.length + this.details.usages.usagesInNonAccessibleProjects;
    this.showUsage = this.numberOfUsages < 10;
    this.datasetFeatures = this.details.datasetFullInfo.dataset.schema.columns.map((col) => ({
      name: col.name,
      comment: col.comment,
      type: col.type,
      meaning: col.meaning || null,
    }));
    this.navigateTitle = this.disableNavigator() ? "You don't have access to the source project" : "Navigate around";
    this.viewInProjectTitle = this.disableViewInProject() ? "You don't have access to the source project" : "See in flow";
  }

  formatProjectName() {
    return formatProjectName(this.highlight, this.details.projectName || "");
  }

  hasShortDescHighlighted() {
    return hasShortDescHighlighted(this.highlight);
  }

  formatShortDesc() {
    return formatShortDesc(this.highlight, this.details.datasetFullInfo.dataset.shortDesc || "");
  }

  hasDescriptionHighlighted() {
    return hasDescriptionHighlighted(this.highlight);
  }

  formatDescription() {
    return formatDescription(this.highlight, this.details.datasetFullInfo.dataset.description || "");
  }

  featureGroupType() {
    return this.typeMappingService.mapDatasetTypeToName(this.details.datasetFullInfo.dataset.type);
  }

  flowLink(projectKey: string, datasetName: string) {
    return this.featureStoreService.flowLink(projectKey, datasetName);
  }

  viewInProject() {
    if(!this.disableViewInProject()) this.featureStoreService.viewInProject(
      this.details.datasetFullInfo.dataset.projectKey,
      this.details.datasetFullInfo.dataset.name
    );
  }

  disableViewInProject() {
    return this.featureStoreService.disableViewInProject(this.details);
  }

  linkToProject(projectKey: string) {
    return this.featureStoreService.linkToProject(projectKey);
  }

  showNavigator(projectKey: string, datasetName: string) {
    this.featureStoreService.showNavigator(projectKey, datasetName);
  }

  disableNavigator() {
    return this.featureStoreService.disableNavigator(this.details);
  }

  lastBuildInfo() {
    return this.featureStoreService.lastBuildInfo(this.details);
  }

  tagBackgroundColor(projectKey: string, tag: string) {
    return this.featureStoreService.tagBackgroundColor(projectKey, tag);
  }
}
