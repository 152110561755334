<nav class="left-pane" [ngClass]="{ 'left-pane--closed' : !isOpen }">

    <ng-content select="[header]"></ng-content>
    <ng-content></ng-content>
    <div class="left-pane__footer">
        <ng-content select="[footer]"></ng-content>
    </div>
  
    <div *ngIf="hasHandle" (click)="toggleMenu()" class="left-pane__handle"></div>

</nav>
