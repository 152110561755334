import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { APIError } from '@core/dataiku-api/api-error';
import { AppConfig } from '@shared/models';
import { ProjectsService } from '@model-main/server/services/projects-service';
import { Dashboard } from '@model-main/dashboards/model/dashboard';
import { DashboardOptions } from '@migration/modules';
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, switchMap, throwError } from 'rxjs';
import { ObjectViewer } from '../object-viewer-host';

@Component({
    selector: 'dashboard-viewer',
    templateUrl: './dashboard-viewer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardViewerComponent extends ObjectViewer implements OnInit {
    appConfig$ = new BehaviorSubject<AppConfig | null | undefined>(undefined);
    project$ = new BehaviorSubject<ProjectsService.UIProject | null | undefined>(undefined);

    dashboardOptions$: Observable<DashboardOptions | undefined>;

    @Input()
    set appConfig(value: AppConfig | undefined) {
        this.appConfig$.next(value);
    }

    @Input()
    set project(value: ProjectsService.UIProject | undefined) {
        this.project$.next(value);
    }

    constructor(private dataikuApiService: DataikuAPIService) {
        super();
    }

    private getDashboard(projectKey: string, id: string): Observable<Dashboard | undefined> {
        return this.dataikuApiService.dashboards.get(projectKey, id).pipe(
            catchError((err: APIError) => {
                this.handleError(err);
                return throwError(() => err);
            })
        );
    }

    ngOnInit(): void {
        this.dashboardOptions$ = this.options$.pipe(
            switchMap((options) => {
                let dashboard$: Observable<Dashboard | undefined> = of(undefined);

                if (options) {
                    dashboard$ = this.getDashboard(options.projectKey, options.id);
                }

                return combineLatest([this.appConfig$, this.project$, dashboard$]);
            }),
            map(([appConfig, project, dashboard]) => {
                const dashboardOptions: DashboardOptions = {
                    uiState: {
                        currentPageIdx: 0
                    },
                    dashboard
                };

                if (appConfig && project) {
                    dashboardOptions.canEditDashboard = (dashboard: Dashboard) =>
                        dashboard &&
                        project?.canWriteDashboards &&
                        (project?.canModerateDashboards || dashboard.owner == appConfig.login);
                }

                return dashboardOptions;
            })
        );
    }
}
