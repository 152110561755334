import { Highlight } from "./query-result-models";

export function getHighlightFor(highlight: Highlight, field: keyof Highlight) {
  return highlight[field]?.[0];
}

export function formatProjectName(highlight: Highlight, raw: string) {
  return getHighlightFor(highlight, "projectName") ?? getHighlightFor(highlight, "projectName.plaintext") ?? raw;
}

export function formatProjectNameOrKey(highlight: Highlight, raw: string) {
  return (
    getHighlightFor(highlight, "projectName") ??
    getHighlightFor(highlight, "projectName.plaintext") ??
    getHighlightFor(highlight, "projectKey") ??
    getHighlightFor(highlight, "projectKey.plaintext") ??
    raw
  );
}

export function formatShortDesc(highlight: Highlight, raw: string) {
  const highlightedShortDesc = getHighlightFor(highlight, "shortDesc");
  const highlightedShortDescPlainText = getHighlightFor(highlight, "shortDesc.plaintext");
  return highlightedShortDesc
    ? highlightedShortDesc
    : highlightedShortDescPlainText
    ? highlightedShortDescPlainText
    : raw;
}

export function formatDescription(highlight: Highlight, raw: string) {
  const highlightedDescription = getHighlightFor(highlight, "description");
  const highlightedDescriptionPlainText = getHighlightFor(highlight, "description.plaintext");
  return highlightedDescription
    ? highlightedDescription
    : highlightedDescriptionPlainText
    ? highlightedDescriptionPlainText
    : raw;
}

export function formatSourceFeatureGroupName(highlight: Highlight, raw: string) {
  return (
    getHighlightFor(highlight, "dataset") ??
    getHighlightFor(highlight, "dataset.raw") ??
    getHighlightFor(highlight, "dataset.plaintext") ??
    raw
  );
}

export function formatItemName(highlight: Highlight, raw: string) {
  return (
    getHighlightFor(highlight, "name") ??
    getHighlightFor(highlight, "name.raw") ??
    getHighlightFor(highlight, "name.plaintext") ??
    raw
  );
}

export function hasShortDescHighlighted(highlight: Highlight) {
  return (highlight.shortDesc?.length || highlight["shortDesc.plaintext"]?.length || 0) > 0;
}

export function hasDescriptionHighlighted(highlight: Highlight) {
  return (highlight.description?.length || highlight["description.plaintext"]?.length || 0) > 0;
}
