<div class="line">
    <h4>Type</h4>
    <p>{{selectedFeature._source.type | lowercase | capitalize}}</p>
</div>
<div class="line">
    <h4>Meaning</h4>
    <p>{{selectedFeature._source.meaning | meaningLabel}}</p>
</div>
<div class="block" *ngIf="!!selectedFeature._source.description">
    <h4>Description</h4>
    <markdown class="markdown-description"
        [class.highlight-markdown]="hasDescriptionHighlighted()"
        [from]="formatDescription()"></markdown>
</div>
<hr/>
<div class="line feature-store__feature-group-actions">
    <h4 [attr.data-qa-smid]="'source-feature-group-label'">Source Feature Group</h4>
    <p><a [href]="datasetLink()" class="highlight" [innerHtml]="formatFeatureGroupName() | escapeHighlightedHtml"></a></p>
    <a (click)="useInProject()" class="btn btn--secondary btn--icon" alt="Use in project" [matTooltip]="useInProjectTitle"
        [class.disabled]="disableUseInProject()" wt1Click="feature-group-use" [attr.data-qa-smid]="'source-fg-use-it'+(disableUseInProject()?'-disabled':'')">
        <i class="icon-dku-share"></i> Use Feature Group
    </a>
</div>
<div class="line feature-store__feature-group-actions">
    <h4 [attr.data-qa-smid]="'source-project-label'">Source project</h4>
    <p><a [href]="linkToProject()" class="highlight" [innerHtml]="formatProjectName() | escapeHighlightedHtml"></a></p>
    <a (click)="showNavigator()" class="btn btn--secondary btn--icon"
        [matTooltip]="navigateTitle" [class.disabled]="disableNavigator()">
        <i class="icon-compass" [attr.data-qa-smid]="'navigate-around'"></i>
    </a>
    <a class="btn btn--secondary btn--icon" (click)="viewInProject()"
        [matTooltip]="viewInProjectTitle" [class.disabled]="disableViewInProject()">
        <i class="icon-dku-nav_flow" [attr.data-qa-smid]="'flow-button'"></i>
    </a>
</div>
<div class="accordion">
    <h4 *ngIf="selectedFeature" class="feature-store__feature-group-details-title accordion-title" (click)="showFeatureGroupDetails=!showFeatureGroupDetails" [attr.data-qa-smid]="'feature-group-details-title'">
        <i [ngClass]="{'icon-chevron-up':showFeatureGroupDetails, 'icon-chevron-down':!showFeatureGroupDetails}"></i>
        Feature Group Details
    </h4>
    <div [hidden]="selectedFeature && !showFeatureGroupDetails">
        <feature-group-details
            [projectKey]=projectKey
            [details]=details
            [highlight]={}
            [selectedFeature]=selectedFeature
            [featureHighlight]=highlight
            [users]=users
            [datasetsInCurrentProject]=datasetsInCurrentProject
            ></feature-group-details>
    </div>
</div>